import { RecipientStatus, RequestStatus } from '@/types/types';

const translations = {
    en: {
        account: {
            annualPayment: 'Annual Payment (-20%)',
            businessPlan: 'Business Plan',
            canceled: 'canceled',
            checkEmail: 'Please check your email address',
            comparePlans: 'Compare plans',
            DeleteAccount: 'Delete Account',
            deleteConfirmationTitle: 'Please confirm your account deletion',
            emailVerificationFailed: 'Email verification has failed.',
            emailVerificationSuccess:
                'Your email address has been successfully verified!',
            free: 'free',
            month: 'month',
            monthlyPayment: 'Monthly payment',
            planUpdateSuccess: 'Successfully updated.',
            resetPaswordTitle: 'Reset password',
            saveSuccess: 'Successfully saved.',
            trialText: '14 days trial period',
            noVatText: 'plus VAT',
            validTo: 'valid to',
            year: 'year',
            Seats: 'Seats',
            pendingSeat: 'Pending confirmation...',
            completedSeat: 'Completed',
            seatInvited: 'Invitation successfully sent!',
            seatDeleted: 'Seat has been deleted!',
            teamDeleted: 'Team has been deleted!',
            teamUpdated: 'Team has been updated!',
            confirmSeatDeletion: 'Are you sure you want to delete this user?',
            confirmTeamDeletion: 'Are you sure you want to delete this team?',
            TypeTheEmail: 'Type the email address...',
            seatsInfo:
                'You can allocate available seats to other users by invitation. Users must be registered and must accept your invitation.',
            signatureInfo:
                'This signature will become your default signature selection when signing a document. If needed it can be changed when signing.',
            seatInviteAcceptConfirmation:
                'Please confirm the invitation acceptance',
            seatInviteAcceptDeclineConfirmation:
                'Please confirm the invitation decline',
            invitedBy: 'invited by',
            Teams: 'Teams',
            teamsInfo:
                'Sent documents will be shared between team members. Teams can only consist of users who already accepted your seat invitation.',
            PlanInfo:
                'You may purchase multiple seats by changing the quantity at checkout. Seats can be allocated to users using their registered email addresses.',
        },
        api: {
            Usage: 'Usage',
            ValidTo: 'Valid to',
        },
        auth: {
            alreadyRegisteredQ: 'Already registered?',
            emailAddress: 'Email address',
            emailInUse: 'Email already registered',
            forgotPasswordQ: 'Forgot password?',
            inputEmailAddress: 'Type your email address...',
            inputName: 'Company or person...',
            inputPassword: 'Type your password...',
            login: 'Login',
            loginWithGoogle: 'Login with Google',
            name: 'Name',
            notRegisteredQ: 'Not registered?',
            password: 'Password',
            passwordComplexity: 'Minimum 8 characters',
            register: 'Sign up',
            registerWithGoogle: 'Sign up with Google',
            tos: 'Terms and Conditions',
            tosMessage: 'By signing up you agree with our',
            wrongEmailOrPasswords: 'Incorrect email or password',
        },
        bulk: {
            Completed: 'Completed',
            completedTable: 'Completed table',
            completedTableTootlip:
                'This file contains the signers email addresses.',
            createSuccess: 'Succesfully created!',
            csvError: 'Extract error!',
            csvSuccess:
                'Csv file successfully processed! {{number}} valid sign requests',
            Date: 'Date',
            errorRows: 'Error rows',
            errorRowsTooltip:
                'Double check the email address format and whether there are duplicates on a row.',
            fileTypeErr: 'File type must be .csv',
            instructions: 'Instructions',
            instructionsIntro:
                'Bulk send allows sending the same document to multiple recipients with a single operation. Instead of manually inputing all email addresses with each template use, with bulk send you can upload a csv file.',
            instructionsStep1: `Step 1: Download the below provided table header. Each column corresponds to a signer defined in the template.`,
            instructionsStep2: `Step 2: Fill in the .csv file with the signer's email addresses and save it as .csv (you may use tools like Google Sheets). Each row will generate a separate sign request.`,
            instructionsStep3: `Step 3: Upload the the completed .csv file.`,
            instructionsStep4: `Step 4: Send. The generated sign requests will appear progresively in the Send Documents list.`,
            pageTitle: 'Bulk send',
            Sent: 'Sent',
            sentPageTitle: 'Bulk sent',
            tableHeader: 'Table header',
            tableHeaderTootltip:
                'This files contains the table header and need to be filled in with the signers email addresses.',
            Total: 'Standalone requests',
            uploadCompletedTable: 'Upload the completed table.',
            uploadCsvFile: 'Upload .csv file',
            validRows: 'Valid rows',
            validRowsTootlip:
                'Each row will generate an individual sign request.',
        },
        buttons: {
            add: 'Add',
            Back: 'Back',
            backCancel: 'Cancel',
            cancel: 'Cancel',
            delete: 'Delete',
            download: 'Download',
            edit: 'Edit',
            extract: 'Extract',
            manage: 'Manage',
            newSignRequest: 'New Sign Request',
            save: 'Save',
            send: 'Send',
            sendBulk: 'Bulk send',
            sign: 'Sign',
            use: 'Use',
            Invite: 'Invite',
            Accept: 'Accept',
            Decline: 'Decline',
            CreateNewTeam: 'Create New Team',
        },
        common: {
            Account: 'Account',
            Cancel: 'Cancel',
            checkbox: 'checkbox',
            Checkbox: 'Checkbox',
            checkboxes: 'checkboxes',
            FileAttachment: 'File',
            fileAttachment: 'file',
            fileAttachments: 'files',
            Completed: 'Completed',
            Confirm: 'Confirm',
            Document: 'Document',
            Fields: 'Fields',
            genericError:
                'Oops, there was an error. We have been notified about it.',
            InProgress: 'In progress',
            mandatory: 'mandatory',
            Mandatory: 'Mandatory',
            No: 'No',
            onlyWithBusinessPlan: 'Available only with a Business plan.',
            Options: 'Options',
            Sentby: 'Sent by',
            CreatedBy: 'Created by',
            signature: 'signature',
            Signature: 'Signature',
            signatures: 'signatures',
            Signer: 'Signer',
            Signers: 'Signers',
            stamp: 'stamp',
            Stamp: 'Stamp',
            stamps: 'stamps',
            Status: 'Status',
            Tags: 'Tags',
            text: 'text',
            Text: 'Text',
            texts: 'texts',
            Yes: 'Yes',
            Enable: 'Enable',
            Enabled: 'Enabled',
            UpdateSuccess: 'Succesfully updated!',
            Copy: 'Copy',
            SingLinkCopySuccess: 'Sing link has been successfully copied!',
        },
        createSR: {
            addSigner: 'Add signer',
            converting: 'Converting to PDF...',
            merging: 'Merging documents...',
            duplicateEmail: 'Duplicate email',
            duplicateNumber: 'Duplicate number',
            emailAddPh: 'Add message',
            emailRecipientPh: 'Email address...',
            emailTitle: 'Message (optional)',
            emailTitleTooltip: 'You may add a custom email message to signers.',
            extractTagsSuccess: '{{fieldsCount}} fields have been extracted!',
            extractTagsZero:
                'No field found! Make sure you have tags like {{s:1}} or {{t:1____ }}',
            fieldsMessage:
                'You have {{signaturesFieldsCount}} signatures, {{stampFieldsCount}} stamps and {{textFieldsCount}} text fields.',
            fieldsMessagePrefix: 'You have',
            fieldsMinSignersErr: 'Please add at least one signer',
            fieldsTitle: 'Signatures and other fields (optional)',
            fieldsTitleTooltip:
                'You can indicate the positions on the document for signatures or other fields. Some fields can be extracted if added as tags inside the content of the document. Ex: {{s:1}} for signatures or {{t:1_____}} for text, where the number indicates the signer.',
            fileSizeErr: 'File size is over the max allowed size of',
            fileTypeErr: 'File must be pdf, doc or docx',
            incorectEmail: 'Incorrect email',
            incorectNumber: 'Incorrect number',
            isCopyOnly: 'Send a copy only',
            isCopyOnlyTooltip:
                'This recipient only gets a copy of the signed document without signing it.',
            leftChars: 'left characters',
            MinNameChars: 'Minimum {{number}} characters',
            moreSignerWithBusiness:
                'More recipients available with the Busines plan.',
            noFieldsMessage: 'You have no fields.',
            oneClickSign: 'Sign with one click',
            oneClickSignTooltip:
                'Used in conjunction with SMS and positioning a signature on the document. Signer will only need to click once on the signature field and the signature will be filled with its name',
            EnglishLanguage: 'English language',
            SignLinkOnly: 'Sign link only',
            SignLinkOnlyTootlip:
                'Only generate the unique signing link. You may use this option if you need to send the signing link yourself through other channels or the person signs at the same computer. After sending the sign request, open the details window and you can find the unique signing link.',
            SendBySMS: 'SMS',
            EnglishLanguageTooltip:
                'Notification emails and the signing page will be in the english language',
            prefillInfoBubble1:
                'On this page you can prefill the document with various text data.',
            prefillInfoBubble2:
                'The text data added here will be premanently applied on the document once sent and can not be edited later.',
            prefillMessage: 'You have added {{number}} texts.',
            prefillPageTitle: 'Prefill document',
            prefillTitle: 'Prefill data (optional)',
            prefillTitleTootlip:
                'You may fill or add data on the document before being sent to the signers.',
            SelectSigner: 'Select signer',
            sentSuccess: 'Successfully sent!',
            SignerNamePh: 'Name...',
            signInOrder: 'Sign in order',
            signInOrderTooltip:
                'Signers will be notified only after the previous one has signed.',
            signMySelf: 'I am also signing',
            signMyselfTooltip:
                'You will also be added to the signer list and you will be able to sign after sending the request.',
            smsNotifOption: 'Send through SMS',
            smsNotifOptionTooltip:
                'This signer will get the secure signing link through SMS.',
            tagsAddPh: 'Add one or more tags...',
            tagsTitle: 'Tags (optional)',
            tagsTitleTooltip:
                'You may use tags to categorize your sign requests.',
            templateRecipientPh: 'Role, name, email, etc...',
            templateSaveSuccess: 'Successfully saved!',
            uploadDoc: 'Upload a document',
            uploadErr: 'File upload error.',
            uploadFile: 'Upload file',
            addFile: 'Add file',
            verifyEmail:
                'Please verify your email address in order to send more sign requests.',
            videoId: 'Video identification',
            videoIdTooltip:
                'Signer will only be able to sign after verification',
        },
        editFields: {
            fieldDescription: 'Description (optional)',
            textFieldDescriptionPh: 'E.g. Name, Address, etc...',
            fileAttachFieldDescriptionPh: 'E.g. ID, Certificate, etc...',
            howToDrag: 'Hold and drag the field on to the document',
            infoBubble1:
                'Fields added on the document will be visualised and filled by signers.',
            infoBubble2:
                'After adding a field you will have to select the signer.',
            infoBubble3: 'You have added {{fieldModeFieldsCount}} fields.',
            infoBubble4:
                'All fields have recipients assigned. You may save them.',
            infoBubble5:
                '{{noRecipientFieldsCount}} fields dont have a signer assigned.',
            infoBubble6: 'Drag and drop the field on the document.',
            infoBubble7:
                'Signer will not be able to complete signing without filling this field.',
            infoBubble8:
                'This field can only be seen and filled by the selected signer.',
            infoBubble9:
                'A short description can indicate the signer what to fill in.',
            inputTextPh: 'Add text...',
            noSignerFields: 'You have fields without a signer',
            title: 'Add and edit fields',
        },
        homePage: {
            subTitle: 'Sent and received documents summary',
        },
        menu: {
            account: 'ACCOUNT',
            bulkSent: 'Bulk Requests',
            contact: 'Contact',
            free: 'free',
            home: 'Home',
            logout: 'Logout',
            myAccount: 'My Account',
            newRequest: 'New Sign Request',
            personalPlan: 'Personal Plan',
            receivedRequests: 'Received Requests',
            sentDocs: 'Sent Documents',
            templates: 'Templates',
            upgrade: 'UPGRADE',
        },
        recipientStatus: {
            [RecipientStatus.SIGNED]: 'Signed',
            [RecipientStatus.PENDING]: 'Pending',
            [RecipientStatus.NEXT]: 'Next',
            [RecipientStatus.WAITING]: 'Waiting',
            [RecipientStatus.SIG_RECEIVED]: 'Processing',
            [RecipientStatus.SMS_ERROR]: 'SMS Error',
            [RecipientStatus.VIDEO_ID_ERROR]: 'ID Error',
        },
        recipientStatusTitle: {
            [RecipientStatus.SIGNED]: 'Recipient has signed.',
            [RecipientStatus.PENDING]: 'Recipient has not yet signed.',
            [RecipientStatus.NEXT]: 'REcipient is next for signing.',
            [RecipientStatus.WAITING]: 'Recipient waits for its turn.',
            [RecipientStatus.SIG_RECEIVED]: 'Processing.',
            [RecipientStatus.SMS_ERROR]: 'SMS sending error.',
            [RecipientStatus.VIDEO_ID_ERROR]: 'Video id verification failed.',
        },
        requestDetails: {
            additionalFiles: 'Additional Files',
            FileAttachments: 'Signer attachments',
            completedDate: 'Completed Date',
            copyOnlySigners: 'Copy only recipients',
            Loading: 'Loading...',
            NotYetPossible: 'Not yet possible',
            reminder: 'Reminder',
            reminderSuccessMessage: 'Notification successfully sent!',
            Send: 'Send now',
            sentBy: 'Sent by',
            sentDate: 'Sent Date',
            SuccessfullySent: 'Successfully sent',
            tooltipMessage1: 'You can send a reminder every 24h.',
            tooltipMessage2:
                'These recipients will receive copies of signed documents.',
        },
        requestsListPage: {
            Details: 'Details',
            Document: 'Document',
            mainButtonTitleRequest: 'New sign request',
            mainButtonTitleTemplate: 'New template',
            No: 'No',
            noRequestFound: 'No sign request found.',
            SentDate: 'Sent date',
            Sign: 'Sign',
            Status: 'Status',
            templateInfoText: `Templates allow saving and reusing a sign request.`,
            Waiting: 'Waiting',
            YouSigned: 'You signed',
            TemplateDeleteConfirmation: 'Please confirm the template delete',
        },
        requestStatus: {
            [RequestStatus.COMPLETED]: 'Completed',
            [RequestStatus.IN_PROGRESS]: 'In Progress',
            [RequestStatus.PROCESSING]: 'Processing',
            [RequestStatus.PREFILLING]: 'Processing',
            [RequestStatus.CANCELED]: 'Canceled',
            [RequestStatus.TEMPLATE]: 'Template',
        },
        requestStatusTitle: {
            [RequestStatus.COMPLETED]: 'All signers have signed.',
            [RequestStatus.IN_PROGRESS]: 'Waiting for signers to sign.',
            [RequestStatus.PROCESSING]: 'Check status in a few minutes.',
            [RequestStatus.PREFILLING]: 'Check status in a few minutes.',
            [RequestStatus.CANCELED]: 'Sign request has been canceled.',
            [RequestStatus.TEMPLATE]: 'Template',
        },
        routes: {
            AccountPageTitle: 'My account',
            AccountRoute: '/account',
            ApiPageTitle: 'API Settings',
            ApiRoute: '/api-config',
            BulkSendCreatePageTitle: 'Create bulk sign request',
            BulkSendCreateRoute: '/bulk/create',
            BulkSentPageTitle: 'Bulk sent requests',
            BulkSentRoute: '/bulk',
            CreateFromTemplatePageTitle: 'Create from template',
            CreateFromTemplateRoute: '/requests/from-template',
            CreateRequestPageTitle: 'Create new sign request',
            CreateRequestRoute: '/requests/new',
            CreateTemplate: '/templates/new',
            CreateTemplatePageTitle: 'Create template',
            CreateTemplateRoute: '/templates/new',
            EditFieldsPageTitle: 'Edit fields',
            EditFieldsRoute: '/requests/new/fields',
            EditTemplatePageTitle: 'Edit template',
            EditTemplateRoute: '/templates/edit',
            EmailVerificationPageTitle: 'Email verification',
            EmailVerificationRoute: '/email/verify',
            HomePageTitle: 'Home',
            HomeRoute: '/home',
            LoginPageTitle: 'Login',
            LoginRoute: '/login',
            PreFillDocumentPageTitle: 'Pre-fill document',
            PreFillDocumentRoute: '/requests/new/prefill',
            ReceivedRequestsPageTitle: 'Received sign requests',
            ReceivedRequestsRoute: '/requests/received',
            ResetPasswordPageTitle: 'Password reset',
            ResetPasswordRoute: '/password-reset',
            SentRequestsPageTitle: 'Sent documents',
            SentRequestsRoute: '/requests/sent',
            SignErrorPageTitle: 'Sign error',
            SignErrorRoute: '/sign-error',
            SignPageTitle: 'Sign',
            SignRoute: '/sign',
            SignSuccessPageTitle: 'Successfully signed',
            SignSuccessRoute: '/sign-succes',
            SignupPageTitle: 'Sign Up',
            SignupRoute: '/signup',
            SuperRoute: '/super',
            TemplatesPageTitle: 'Templates',
            TemplatesRoute: '/templates',
            CompletedDocumentRoute: '/document/completed',
            CompletedDocumentPageTitle: 'Signed document download',
        },
        search: {
            searchPh: 'Search documents...',
            selectFilters: 'Select filters...',
            selectTimeRange: 'Select time range...',
        },
        sign: {
            AlreadySigned: 'You have already signed.',
            andFill: 'and fill {{nonSigsCount}} fields',
            Check: 'Check',
            closeWindow: 'You may close the window now.',
            createAccount: 'Create account',
            createAccountCTA:
                'Create an account so you can track all your signed documents.',
            docHasBeenSigned: 'The document has been signed',
            docSentByEmail: `You will receive the signed document at your email address. You can also create an account so you can track all your signed documents.`,
            Draw: 'Draw',
            error: 'error',
            errorEncountered: 'There was an',
            extraFields: 'Extra fields',
            FailedVideoId: 'Video id has failed.',
            GoToField: 'Go to field',
            imageFileErr: 'The image must be png, jpg or jpeg.',
            fileSizeErr: 'The file is over the max size of {{maxMB}}MB.',
            fileAttachExtErr: 'File must be a document type, image or zip',
            infoBubble1: 'Touch the highlighted area to sign.',
            infoBubble2: 'Drag and drop the field directly on the document.',
            infoBubble3: 'Click on the highlighted area to sign or fill.',
            InputName: 'Name...',
            InputText: 'Text...',
            InvalidSigner: 'You dont have the right to sign this document.',
            NotFound: 'Sign request not found.',
            RequestCanceled: 'Sign request has been canceled.',
            RequestCompleted: 'Sign request has already been completed.',
            Reset: 'Reset',
            SeeDetails: 'Details',
            select: 'Select',
            SignerNotNext: 'Please wait for your turn.',
            signSuccess: 'You have successfully signed!.',
            signSuccessMessage:
                'The completed document will be sent to your email address or by SMS. Create an account or find out more on',
            sigTitle: 'Select signature type',
            fileAttachmentTitle: 'Upload a file',
            successfully: 'successfully',
            textTitle: 'Add text',
            UnCheck: 'UnCheck',
            Uploadimage: 'Upload image',
            youHaveToSign: 'You have to sign in {{sigsCount}} places',
            SignHere: 'Sign here',
        },
        summary: {
            noReceivedRequest: 'No sign request received.',
            noSentRequest: 'Have your documents signed, simple and quickly.',
            receivedTitle: 'Received requests summary',
            secondaryTitle: 'See details',
            sentTitle: 'Sent documents summary',
            verifyEmail: 'Please verify your email address.',
        },
        other: {
            completedDocumentTitle: 'Completed document download',
            completedDocumentSubTitle:
                'Document has been succesfully signed and can be downloaded here:',
        },
    },
    ro: {
        account: {
            annualPayment: 'Plată anuală (-20%)',
            businessPlan: 'Plan Business',
            canceled: 'anulat',
            checkEmail: 'Verificați-vă adresa de email',
            comparePlans: 'Compară planurile',
            DeleteAccount: 'Închide Contul',
            deleteConfirmationTitle: 'Vă rugăm confirmați ștergerea contului',
            emailVerificationFailed: 'Verificarea adresei de email a eșuat.',
            emailVerificationSuccess:
                'Adresa dvs. de email a fost verificată cu succes.',
            free: 'gratuit',
            month: 'lună',
            monthlyPayment: 'Plată lunară',
            planUpdateSuccess: 'Planul a fost actualizat cu success.',
            resetPaswordTitle: 'Resetează parola',
            saveSuccess: 'Modificările au fost salvate cu succes.',
            trialText: 'primele 14 zile gratuite',
            noVatText: 'fără TVA',
            validTo: 'valabil până la',
            year: 'an',
            Seats: 'Scaune',
            pendingSeat: 'Se așteaptă confirmarea...',
            completedSeat: 'Finalizat',
            seatInvited: 'Invitatie trimisa cu succes!',
            seatDeleted: 'Utilizatorul a fost șters!',
            teamDeleted: 'Echipa a fost ștearsă!',
            teamUpdated: 'Echipa a fost actualizată!',
            confirmSeatDeletion:
                'Ești sigur că vrei să ștergi acest utilizator?',
            confirmTeamDeletion: 'Ești sigur că vrei să ștergi acestă echipă?',
            TypeTheEmail: 'Introduceti adresa de email...',
            seatsInfo:
                'Puteți invita alți utilizatori la planul dvs. Aceștia trebuie să fie deja înregistrați și trebuie să accepte invitația dvs.',
            signatureInfo:
                'Semnătura configurată aici va fi aleasă automat în momentul semnării unui document. Dacă este nevoie această va putea fi modificată la semnarea documentului.',
            seatInviteAcceptConfirmation:
                'Vă rugăm confirmați acceptarea invitației',
            seatInviteAcceptDeclineConfirmation:
                'Vă rugăm confirmați refuzul invitației',
            invitedBy: 'invitat de',
            Teams: 'Echipe',
            teamsInfo:
                'Documentele trimise vor fi partajate intre membrii echipelor. Echipele pot fi formate doar din utilizatori care au acceptat deja invitatia la planul dvs.',
            PlanInfo:
                'Puteti achizitiona mai multe scaune (i.e utilizatori) schimband cantitatea la checkout. Ulterior veti putea aloca aceste scaune catre utilizatori folosind adresele acestora de email cu care s-au inregistrat.',
        },
        api: {
            Usage: 'Consum',
            ValidTo: 'Valabilitate până la',
        },
        auth: {
            alreadyRegisteredQ: 'Ai deja cont?',
            emailAddress: 'Adresă email',
            emailInUse: 'Adresă de email existentă',
            forgotPasswordQ: 'Ai uitat parola?',
            inputEmailAddress: 'Introduceți adresa de email...',
            inputName: 'Companie sau persoană fizică...',
            inputPassword: 'Introduceți parola...',
            login: 'Loghează-te',
            loginWithGoogle: 'Loghează-te cu Google',
            name: 'Nume',
            notRegisteredQ: 'Nu ai cont?',
            password: 'Parolă',
            passwordComplexity: 'Minim 8 caractere',
            register: 'Înregistrează-te',
            registerWithGoogle: 'Înregistrare cu Google',
            tos: 'Termenii si Condițiile',
            tosMessage: 'Înregistrându-vă sunteți de acord cu',
            wrongEmailOrPasswords: 'Email sau parola incorecte',
        },
        bulk: {
            Completed: 'Trimis',
            completedTable: 'Tabel completat',
            completedTableTootlip:
                'Fisierul csv trebuie sa contina capul de tabel descarcat la pasul precedent. Din acest fisier se vor extrage adresele de email sau numerele de telefon ale semnatarilor.',
            createSuccess: 'Trimiterea bulk a fost inregistrata cu succes!',
            csvError: 'Eroare extragere!',
            csvSuccess:
                'Fisierul a fost procesat cu succes! {{number}} cereri valide',
            Date: 'Dată',
            errorRows: 'Rânduri cu erori',
            errorRowsTooltip:
                'Verificati formatul adreselor de email sau a numerelor de telefon. De asemenea, verificati daca aveti duplicate pe acelasi rand.',
            fileTypeErr: 'Fisierul trebuie sa fie .csv',
            instructions: 'Instrucțiuni',
            instructionsIntro: `Trimiterea în bulk permite folosirea unui template
                                pentru a trimite același document către mai mulți
                                semnatari dintr-o singură operațiune. În loc de a
                                introduce adresele de email ale semnatarilor manual la
                                fiecare folosire a template-ului, la trimitere bulk se
                                va încarcă un tabel pre-completat.`,
            instructionsStep1: `Pas 1: Descarcati capul de tabel pus la
                                dispozitie mai jos la sectiunea Cap de tabel. Fiecare
                                coloana corespunde unui semnatar definit in template.`,
            instructionsStep2: `Pas 2: Completati fisierul .csv cu
                                adresele de email sau numerele de telefon ale
                                semnatarilor si salvati ca fisier .csv (puteti folosi de
                                exemplu Google Sheets). Fiecare rand va genera o cerere
                                de semnare separata.`,
            instructionsStep3: `Pas 3: Încărcați fisierul completat
                                folosind sectiunea Tabel completat.`,
            instructionsStep4: `Pas 4: Trimiteti. Cererile de semnare
                                generate vor aparea progresiv in lista Documentelor
                                trimise.`,
            pageTitle: 'Trimite in bulk',
            Sent: 'Trimise',
            sentPageTitle: 'Trimiteri bulk',
            tableHeader: 'Cap de tabel',
            tableHeaderTootltip:
                'Acest fisier contine capul de tabel si trebuie completat de dvs. cu adresele de email sau numerele de telefon ale semnatarilor.',
            Total: 'Cereri individuale',
            uploadCompletedTable: 'Încărcați tabelul completat.',
            uploadCsvFile: 'Încarcă fișier .csv',
            validRows: 'Rânduri valide',
            validRowsTootlip:
                'Aceste randuri sunt valide si vor genera fiecare cate o cerere de semnare individuala.',
        },
        buttons: {
            add: 'Adaugă',
            Back: 'Înapoi',
            backCancel: 'Renunță',
            cancel: 'Anulează',
            delete: 'Șterge',
            download: 'Descarcă',
            edit: 'Editează',
            extract: 'Extrage',
            manage: 'Gestionează',
            newSignRequest: 'Cerere Nouă',
            save: 'Salvează',
            send: 'Trimite',
            sendBulk: 'Trimite bulk',
            sign: 'Semnează',
            use: 'Folosește',
            Invite: 'Invită',
            Accept: 'Acceptă',
            Decline: 'Refuză',
            CreateNewTeam: 'Creează echipă nouă',
        },
        common: {
            Account: 'Cont',
            Cancel: 'Renunță',
            checkbox: 'bifă',
            Checkbox: 'Bifă',
            checkboxes: 'bife',
            FileAttachment: 'Fișier',
            fileAttachment: 'fișier',
            fileAttachments: 'fișiere',
            Completed: 'Finalizată',
            Confirm: 'Confirmă',
            Document: 'Document',
            Fields: 'Câmpuri',
            genericError:
                'A apărut o eroare. Am fost informați si o vom remedia curând.',
            InProgress: 'În curs',
            mandatory: 'obligatoriu',
            Mandatory: 'Obligatoriu',
            No: 'Nu',
            onlyWithBusinessPlan: 'Disponibil doar cu planul Business.',
            Options: 'Opțiuni',
            Sentby: 'Trimis de',
            CreatedBy: 'Creat de',
            signature: 'semnătură',
            Signature: 'Semnătură',
            signatures: 'semnături',
            Signer: 'Semnatar',
            Signers: 'Semnatari',
            stamp: 'ștampilă',
            Stamp: 'Ștampilă',
            stamps: 'ștampile',
            Status: 'Stare',
            Tags: 'Etichete',
            text: 'text',
            Text: 'Text',
            texts: 'texte',
            Yes: 'Da',
            Enable: 'Activează',
            Enabled: 'Activ',
            UpdateSuccess: 'Actualizare efectuata cu succes!',
            Copy: 'Copiază',
            SingLinkCopySuccess: 'Link-ul de semnare a fost copiat cu sucess!',
        },
        createSR: {
            addSigner: 'Adaugă semnatar',
            converting: 'Se convertește la PDF...',
            merging: 'Se combină documentele...',
            duplicateEmail: 'Email duplicat',
            duplicateNumber: 'Număr duplicat',
            emailAddPh: 'Adauga mesaj',
            emailRecipientPh: 'Adresă email semnatar...',
            emailTitle: 'Mesaj (opțional)',
            emailTitleTooltip:
                'Poți transmite un mesaj personalizat semnatarilor. Acesta va fi adăugat la emailul de notificare.',
            extractTagsSuccess: '{{fieldsCount}} campuri au fost extrase!',
            extractTagsZero:
                'Nu a fost gasit niciun camp! Asigurati-va ca aveti tag-uri de forma {{s:1}} sau {{t:1____ }}',
            fieldsMessage:
                'Ai poziționat {{signaturesFieldsCount}} semnături, {{stampFieldsCount}} ștampile și {{textFieldsCount}} câmpuri text.',
            fieldsMessagePrefix: 'Ati pozitionat',
            fieldsMinSignersErr: 'Adăugați cel puțin un semnatar',
            fieldsTitle: 'Poziții semnături sau alte informații (opțional)',
            fieldsTitleTooltip:
                'Poți indica semnatarilor locurile pe document în care trebuie sa semneze sau sa completeze diferite informații. De asemenea, puteti extrage campurile daca adaugati anumite etichete in textul documentului. De ex. eticheta {{s:1}} pentru semnatura primului semnatar. ',
            fileSizeErr: 'Fisierul depăseste mărimea maximă de',
            fileTypeErr: 'Fisierul trebuie sa fie pdf, doc sau docx',
            incorectEmail: 'Email incorect',
            incorectNumber: 'Număr incorect',
            isCopyOnly: 'Trimite doar copie',
            isCopyOnlyTooltip:
                'Recipientul doar va primi o copie a documentului semnat. Acesta nu va putea semna.',
            leftChars: 'caractere rămase',
            MinNameChars: 'Minim {{number}} caractere',
            moreSignerWithBusiness:
                'Mai mulți semnatari disponibili doar cu planul Business',
            noFieldsMessage: 'Nu ați poziționat nici un câmp.',
            oneClickSign: 'Semnare cu un click',
            oneClickSignTooltip:
                'Se foloseste impreună cu notificarea prin SMS si indicarea locului de semnare pe document. Semnatarul va trebui doar să atingă locul indicat de dvs. si acesta se va completa automat cu numele semnatarului.',
            EnglishLanguage: 'Limba engleză',
            SignLinkOnly: 'Doar link semnare',
            SignLinkOnlyTootlip:
                'Puteți folosi această opțiune dacă semnatarul semnează de la acelasi calculator ca dvs. sau dacă doriți să furnizați dvs. link-ul de semnare pe alte canale cum ar fi Whatsapp. După trimitere, deschideți detaliile cererii si veti găsi in dreptul semnatarului link-ul unic de semnare.',
            SendBySMS: 'SMS',
            EnglishLanguageTooltip:
                'Notificarile email si pagina de semnare vor fi in limba engleză',
            prefillInfoBubble1:
                'Poți folosi această pagină pentru a adăuga pe document diverse date sau informații.',
            prefillInfoBubble2:
                'Acestea vor fi aplicate permanent pe document in momentul trimiterii lui spre semnare și nu vor mai putea fi modificate ulterior.',
            prefillMessage: 'Ați adăugat {{number}} texte.',
            prefillPageTitle: 'Pre-completează documentul',
            prefillTitle: 'Pre-completare date (opțional)',
            prefillTitleTootlip:
                'Poți adăuga date si informații pe document înainte de trimitere.',
            SelectSigner: 'Alege semnatarul',
            sentSuccess: 'Cererea a fost trimisa cu succes!',
            SignerNamePh: 'Nume semnatar...',
            signInOrder: 'Semnează în ordine',
            signInOrderTooltip:
                'Semnatarii vor primi pe rând documentul în ordinea adăugării lor.',
            signMySelf: 'Semnez și eu',
            signMyselfTooltip:
                'Vei fi adăugat în lista de semnatari și vei putea semna direct în aplicație după trimitere.',
            smsNotifOption: 'Trimite prin SMS',
            smsNotifOptionTooltip:
                'Semnatarul va primi prin SMS link-ul de semnare a documentului si va putea semna direct pe telefonul mobil. Se poate folosi această opțiune pentru semnatarii care nu au adresă de email.',
            tagsAddPh: 'Adaugă una sau mai multe etichete...',
            tagsTitle: 'Etichete (opțional)',
            tagsTitleTooltip:
                'Poți folosi etichete personalizate pentru a organiza cererile pe diferite categorii.',
            templateRecipientPh: 'Rol, nume, email semnatar...',
            templateSaveSuccess: 'Template-ul a fost salvat cu succes!',
            uploadDoc: 'Încărcați un document',
            uploadErr: 'A aparut o eroare la incarcarea documentului.',
            uploadFile: 'Încarcă fișier',
            addFile: 'Adaugă fișier',
            verifyEmail:
                'Pentru a trimite mai multe documente vă rugam confirmați-vă adresa de email prin accesarea link-ului de confirmare din emailul primit la inregistrare.',
            videoId: 'Identificare video',
            videoIdTooltip:
                'Semnatarul va putea semna doar in urma indentificării video.',
        },
        editFields: {
            fieldDescription: 'Descriere (opțional)',
            textFieldDescriptionPh: 'Ex. Nume, Data, Adresa email...',
            fileAttachFieldDescriptionPh: 'Ex. ID, Certificat, etc...',
            howToDrag: 'Tineți apăsat si trageți câmpul direct pe document',
            infoBubble1:
                'Câmpurile adăugate pe document vor fi vizualizate si vor putea fi completate de către semnatari.',
            infoBubble2: 'După adăugarea unui câmp veți selecta semnatarul.',
            infoBubble3: 'Ați poziționat {{fieldModeFieldsCount}} câmpuri.',
            infoBubble4:
                'Toate câmpurile au un semnatar alocat. Puteți salva pozițiile.',
            infoBubble5:
                '{{noRecipientFieldsCount}} câmpuri nu au semnatar alocat.',
            infoBubble6: 'Trageți câmpul dorit direct pe document.',
            infoBubble7:
                'Semnatarul nu va putea finaliza semnarea documentului dacă nu completează acest câmp.',
            infoBubble8:
                'Acest câmp va fi vizualizat si va putea fi completat doar de semnatarul selectat mai sus.',
            infoBubble9:
                'O descriere scurtă îi poate indica semnatarului tipul de informație de completat.',
            inputTextPh: 'Introduceți textul...',
            noSignerFields: 'Aveți câmpuri fără semnatar',
            title: 'Indică locurile semnăturilor',
        },
        homePage: {
            subTitle: 'Aici găsești sumarul documentelor trimise și primite',
        },
        menu: {
            account: 'CONT',
            bulkSent: 'Trimiteri Bulk',
            contact: 'Contact',
            free: 'gratuit',
            home: 'Acasă',
            logout: 'Deloghează-te',
            myAccount: 'Contul Meu',
            newRequest: 'Cerere Nouă',
            personalPlan: 'Plan Personal',
            receivedRequests: 'Cereri Primite',
            sentDocs: 'Documente Trimise',
            templates: 'Template-uri',
            upgrade: 'UPGRADE',
        },
        recipientStatus: {
            [RecipientStatus.SIGNED]: 'Semnat',
            [RecipientStatus.PENDING]: 'Nesemnat',
            [RecipientStatus.NEXT]: 'La rând',
            [RecipientStatus.WAITING]: 'Așteaptă rândul',
            [RecipientStatus.SIG_RECEIVED]: 'Procesare',
            [RecipientStatus.SMS_ERROR]: 'Eroare SMS',
            [RecipientStatus.VIDEO_ID_ERROR]: 'Eroare ID',
        },
        recipientStatusTitle: {
            [RecipientStatus.SIGNED]: 'Semnatarul a semnat.',
            [RecipientStatus.PENDING]: 'Semnatarul nu a semnat încă.',
            [RecipientStatus.NEXT]: 'Este rândul acestul semnatar sa semneze.',
            [RecipientStatus.WAITING]: 'Așteaptă rândul',
            [RecipientStatus.SIG_RECEIVED]: 'Se aplică semnatura pe document.',
            [RecipientStatus.SMS_ERROR]:
                'Trimiterea SMS-ului catre aceste semnatar nu a reusit.',
            [RecipientStatus.VIDEO_ID_ERROR]:
                'Verificarea video nu a avut succes.',
        },
        requestDetails: {
            additionalFiles: 'Fișiere adiționale',
            FileAttachments: 'Fișiere semnatari',
            completedDate: 'Dată Finalizare',
            copyOnlySigners: 'Copie document semnat',
            Loading: 'Se actualizează...',
            NotYetPossible: 'Nu este încă posibil',
            reminder: 'Reamintire',
            reminderSuccessMessage:
                'Notificarea de reamintire a fost trimisă cu succes!',
            Send: 'Trimite',
            sentBy: 'Trimis de',
            sentDate: 'Dată Trimitere',
            SuccessfullySent: 'Trimisă cu succes',
            tooltipMessage1:
                'Puteți trimite o notificare de reamintire la un interval de minim 24h.',
            tooltipMessage2:
                'Se vor trimite copii ale documentului semnat si la aceste adrese de email',
        },
        requestsListPage: {
            Details: 'Detalii',
            Document: 'Document',
            mainButtonTitleRequest: 'Cerere nouă',
            mainButtonTitleTemplate: 'Template nou',
            No: 'Nu',
            noRequestFound: 'Nu s-a găsit nici o cerere.',
            SentDate: 'Dată trimitere',
            Sign: 'Semnează',
            Status: 'Stare',
            templateInfoText: `Template-urile permit salvarea unei cereri de semnătură -
                                                                                document, semnatari, poziții semnături, etc - ca șablon
                                                                                predefinit. Acesta va putea fi refolosit ori de câte ori
                                                                                este nevoie.`,
            Waiting: 'În ordine',
            YouSigned: 'Ai semnat',
            TemplateDeleteConfirmation:
                'Va rugam confirmati stergerea template-ului',
        },
        requestStatus: {
            [RequestStatus.COMPLETED]: 'Finalizată',
            [RequestStatus.IN_PROGRESS]: 'În curs',
            [RequestStatus.PROCESSING]: 'În Procesare',
            [RequestStatus.PREFILLING]: 'În Procesare',
            [RequestStatus.CANCELED]: 'Anulată',
            [RequestStatus.TEMPLATE]: 'Template',
        },
        requestStatusTitle: {
            [RequestStatus.COMPLETED]:
                'Toti semnatarii au semnat acest document.',
            [RequestStatus.IN_PROGRESS]:
                'In asteparea semnăturilor solicitate.',
            [RequestStatus.PROCESSING]:
                'Se aplică semnătura. Verificati starea in cateva minute.',
            [RequestStatus.PREFILLING]: 'Verificati starea in cateva minute.',
            [RequestStatus.CANCELED]: 'Cererea a fost anulată',
            [RequestStatus.TEMPLATE]: 'Sablon',
        },
        routes: {
            AccountPageTitle: 'Contul meu',
            AccountRoute: '/account',
            ApiPageTitle: 'Setări API',
            ApiRoute: '/api-config',
            BulkSendCreatePageTitle: 'Creează cerere bulk',
            BulkSendCreateRoute: '/bulk/create',
            BulkSentPageTitle: 'Cereri trimise in bulk',
            BulkSentRoute: '/bulk',
            CreateFromTemplatePageTitle: 'Creează cerere',
            CreateFromTemplateRoute: '/cereri/from-template',
            CreateRequestPageTitle: 'Creează cerere',
            CreateRequestRoute: '/cereri/noua',
            CreateTemplate: '/templates/noua',
            CreateTemplatePageTitle: 'Creează template',
            CreateTemplateRoute: '/templates/noua',
            EditFieldsPageTitle: 'Indică locurile semnăturilor',
            EditFieldsRoute: '/cereri/noua/semnaturi',
            EditTemplatePageTitle: 'Editează template',
            EditTemplateRoute: '/templates/edit',
            EmailVerificationPageTitle: 'Verificare email',
            EmailVerificationRoute: '/email/verify',
            HomePageTitle: 'Acasă',
            HomeRoute: '/acasa',
            LoginPageTitle: 'Login',
            LoginRoute: '/login',
            PreFillDocumentPageTitle: 'Pre-completează documentul',
            PreFillDocumentRoute: '/cereri/noua/prefill',
            ReceivedRequestsPageTitle: 'Cereri primite',
            ReceivedRequestsRoute: '/cereri/primite',
            ResetPasswordPageTitle: 'Resetează parola',
            ResetPasswordRoute: '/reseteaza-parola',
            SentRequestsPageTitle: 'Documente trimise',
            SentRequestsRoute: '/cereri/trimise',
            SignErrorPageTitle: 'Eroare semnare',
            SignErrorRoute: '/eroare-semnare',
            SignPageTitle: 'Semnează',
            SignRoute: '/semneaza',
            SignSuccessPageTitle: 'Succes semnare',
            SignSuccessRoute: '/succes-semnare',
            SignupPageTitle: 'Înregistrare',
            SignupRoute: '/signup',
            SuperRoute: '/super',
            TemplatesPageTitle: 'Template-uri',
            TemplatesRoute: '/templates',
            CompletedDocumentRoute: '/document/completed',
            CompletedDocumentPageTitle: 'Descărcare document semnat',
        },
        search: {
            searchPh: 'Caută un document...',
            selectFilters: 'Alege filtre...',
            selectTimeRange: 'Selectează perioada...',
        },
        sign: {
            AlreadySigned: 'Ați semnat deja acest document.',
            andFill: 'și de completat {{nonSigsCount}} câmpuri',
            Check: 'Bifează',
            closeWindow: 'Puteți inchide fereastra.',
            createAccount: 'Creează cont',
            createAccountCTA: `Creează-ți cont pentru a putea administra si vedea istoricul
                                documentelor semnate.`,
            docHasBeenSigned: 'Documentul a fost semnat cu',
            docSentByEmail: `Veți primi documentul finalizat la adresa dvs de email.
                                Creează-ți cont gratuit pentru a putea administra si
                                vedea istoricul documentelor semnate.`,
            Draw: 'Desenează',
            error: 'eroare',
            errorEncountered: 'Semnarea a întâmpinat o',
            extraFields: 'Extra câmpuri',
            FailedVideoId: 'Identificarea video a esuat.',
            GoToField: 'Mergi la poziția',
            imageFileErr: 'Fisierul trebuie sa fie png, jpg sau jpeg.',
            fileSizeErr: 'Fisierul depaseste marimea maxima de {{maxMB}}MB',
            fileAttachExtErr:
                'Fisierul trebui sa fie de tip document, imagine sau zip',
            infoBubble1: 'Apăsați pe zona indicată pentru a semna.',
            infoBubble2: 'Trageți câmpul dorit direct pe document.',
            infoBubble3:
                'Faceți click pe zona indicată pentru a semna sau completa.',
            InputName: 'Introduceți numele...',
            InputText: 'Introduceți textul...',
            InvalidSigner: 'Nu ai dreptul de a semna acest document.',
            NotFound: 'Cererea nu a fost gasită.',
            RequestCanceled: 'Cererea a fost anulată.',
            RequestCompleted: 'Cererea a fost deja finalizată.',
            Reset: 'Reseteaza',
            SeeDetails: 'Vezi detalii',
            select: 'Alege',
            SignerNotNext: 'Nu sunteți incă la rand pentru a semna.',
            signSuccess: 'Documentul a fost semnat cu succes.',
            signSuccessMessage:
                'Veți primi documentul semnat de către toți semnatarii la adresa dvs. de email sau prin SMS. Creeaza-ți cont sau află mai multe pe',
            sigTitle: 'Alege tipul de semnătura',
            fileAttachmentTitle: 'Încarcă un fisier',
            successfully: 'success',
            textTitle: 'Adaugă text',
            UnCheck: 'Debifează',
            Uploadimage: 'Încarcă imagine',
            youHaveToSign: 'Aveți de semnat în {{sigsCount}} locuri',
            SignHere: 'Semnează aici',
        },
        summary: {
            noReceivedRequest: 'Nu ai nici o cerere de semnătură primită.',
            noSentRequest:
                'Obține semnăturile de care ai nevoie rapid si sigur.',
            receivedTitle: 'Sumar cereri primite',
            secondaryTitle: 'Vizualizează Detalii',
            sentTitle: 'Sumar documente trimise',
            verifyEmail:
                'Verificați-vă adresa de email pentru a putea vizualiza cererile de semnătură primite',
        },
        other: {
            completedDocumentTitle: 'Descărcați documentul finalizat',
            completedDocumentSubTitle:
                'Documentul a fost semnat cu succes și poate fi descărcat aici:',
        },
    },
};

export { translations };
